@import "../../index.scss";
.home__1 {
  margin-top: 0.6rem;
  .home__box__1 {
    align-items: center;
    .home__rightBox {
      display: grid;
      grid-template-rows: 1fr 1fr 1fr;
      padding: 0 0.6rem;
    }
  }
  .home__box__2 {
    padding: 0 0.6rem;
  }
  .home__box__3 {
    margin: 0.8rem 0;
    padding: 0 0.6rem;
    //check background color, maybe back to silver2
    background: $silver2;
    .home_3_title {
      display: flex;
      justify-content: space-between;
      color: white;
      h2 {
        color: white;
        font-size: 1.4rem;
      }
      span {
        font-size: 1.4rem;
      }
    }
  }
  .home__box__4 {
    padding: 0 0.6rem;
    h3 {
      // color: $green2;
      font-size: 1.4rem;
    }
  }
}
@media screen and (max-width:767px) {
  .home__1 {
    .home__box__1 {
      .mainNews {
        margin-top: 1.5rem;
      }
      .home__rightBox {
        background: $dark6;
      }
    }
    .home > div:nth-child(2) {
      margin-top: auto;
    }
  }
}
@media screen and (min-width:768px) {
  .home__1 {
    padding: 1.5rem 0.5rem;
    .home__box__1 {
      margin: 0.8rem auto;
      border-radius: 10px;
      position: relative;
      align-items: start;
      display: flex;
      flex-direction: column;
      background: linear-gradient(
        to right,
        $orange1 45%,
        $orange3 55%,
        $orange4 65%,
        $orange3 85%,
        $orange2 96%
      );
      box-shadow: 1px 2px 6px grey;
      .home__rightBox {
        margin-right: 0.5rem;
      }
    }
    .home__box__2 {
      position: relative;
      display: flex;
      align-items: start;
      height: 640px;
      margin: 0.8rem auto;
      padding: 0;
      .home__leftBox {
        margin: 0;
        float: left;
        display: grid;
        grid-template-rows: 4 (1fr);
        width: calc(100% - 320px);
      }
      .home__rightBox {
        display: flex;
        justify-content: right;
        align-items: center;
        height: 100%;
        width: 320px;
      }
    }
    .home__box__3 {
      width: 100%;
      background: radial-gradient(
        circle at 100%,
        $grey2,
        $grey3 40%,
        $grey4 80%,
        $grey2 80%
      );
      display: flex;
      flex-direction: column;
      padding: 0.3rem 1.5rem 0.8rem 1.5rem;
      border-radius: 10px;
      .home_3_title {
        font-size: 1.6rem;
        h2 {
          font-size: 2rem;
          font-weight: 500;
        }
        span {
          font-size: 2rem;
        }
      }
      .box3 {
        .box3__left {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          gap: 1rem;
        }
      }
    }
    .home__box__4 {
      h3 {
        font-size: 1.8rem;
      }
      .home__box__4-layout {
        margin-bottom: 1rem;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
      }
    }
  }
}

@media screen and (min-width:1024px) {
  .home__1 {
    .home__box__1 {
      flex-direction: row;
      height: 26rem;
    }
  }
}
