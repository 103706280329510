@import "../../index.scss";
.ad_wrapper {
  // border: 1px solid red;
  margin: 0 auto;
  // justify-content: center;
  text-align: center;
}
.adClass {
  // border: 1px solid blue;
  margin: 0.8rem auto;
  justify-content: center;
  display: flex;
  img {
    height: auto;
  }
}
.adClass__XXL {
  display: none;
}
.adClass_Footer {
  display: none;
}
.adClass__S {
  display: none;
}
@media screen and (min-width:1024px) {
  .adClass {
    img {
      // border: 1px solid red;
      height: 600px;
    }
  }
  .adClass__XXL {
    // padding: 0.3rem;
    display: flex;
    // height: 200px;
    margin: 0.8rem auto;
    justify-content: center;
    img {
      // width: 100%;
      border: 1px solid black;
    }
  }
  .adClass_Footer {
    display: inline;
    position: fixed;
    left: 0;
    top: calc(100vh - 90px);
    margin: 0 auto;
    text-align: center;
    background: rgba(59, 59, 59, 0.85);
    width: 100vw;
    z-index: +999;
    // border: 1px solid red;
  }
}
