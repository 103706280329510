@import "../../index.scss";

.footer {
  // display: none;
  // background: $dark5;
  // height: 60vh;
  .home__section5-top {
    padding: 0.4rem;
    display: flex;
    flex-direction: column;
    // border: 1px solid red;
    // height: 35vh;
    span {
      // border: 1px solid white;
      text-align: left;
      padding: 0.2rem 0;
      color: white;
      color: black;
    }
  }
  .home__section5-middle {
    margin: 0 auto;
    border: 1px 0 1px 0 solid grey;
    border-top: 0.5px solid grey;
    border-bottom: 0.5px solid grey;
    color: white;
    color: black;
    display: flex;
    justify-content: space-between;
  }
  .home__section5-middle > div {
    padding: 0.3rem 0.4rem;
    display: flex;
    flex: 1;
    align-items: center;
    i {
      cursor: pointer;
      font-size: 1.2rem;
      color: $dark0;
      color: black;
      margin-left: 0.5rem;
    }
  }
  .home__section5-middle > div:last-child {
    justify-content: flex-end;
  }
  .home__section5-bottom {
    // width: 30%;
    text-align: center;
    margin: 0 auto;
    color: white;
    color: black;
    padding: 2rem 0;
    // display: flex;
    // flex-direction: column;
    a {
      color: rgb(0, 0, 0);
      font-weight: bold;
      // text-shadow: 0px 0 1px rgb(0, 0, 0);
    }
  }
}
@media screen and (min-width:768px) {
  .footer {
    .home__section5-top {
      border-top: 3px double black;
      padding: 2.5rem 15%;
      // display: grid;
      display: flex;
      flex-direction: row;
      // grid-template-columns: repeat(4, 1fr);
      justify-content: space-between;
      // border: 1px solid red;
      a {
        // border: 1px solid blue;
        text-align: center;
        span {
          text-align: center;
        }
      }
    }
    .home__section5-middle {
      width: 80%;
    }
    .home__section5-middle > div {
      // border: 1px solid white;
      padding: 0.5rem 0.2rem;
      i {
        font-size: 1.4rem;
        margin-left: 1rem;
      }
    }
  }
}
