@import "../../../index.scss";

.side-drawer {
  position: fixed;
  position: absolute;
  top: 50px;
  left: 0;
  bottom: 0;
  min-height: 100vh;
  width: 100%;
  background: white;
  // background: grey;
  // background-image: url("./img/logo3.png");
  transform: translateX(-100%);
  transition: transform 0.5s ease-out;
  z-index: +9999;
  // border: 1px solid red;
  .logo {
    display: flex;
    // justify-content: left;
    // margin: 3rem auto;
    align-items: center;
    text-align: center;
    img {
      margin: 0 auto;
      width: 70%;
      height: auto;
    }
  }
  ul {
    // margin-top: 1rem;
    // padding: 0.5rem;
    list-style: none;
    display: flex;
    flex-direction: column;
    font-size: 1.2rem;
    text-align: center;
    text-align: left;
  }

  li {
    margin: 0.2rem 0;

    .top-link2 {
      display: inline-block;
      width: 100%;
      // border-bottom: 1px solid rgba(0, 50, 0, 0.353);
      font-size: 1.8rem;
      font-weight: 500;
      text-decoration: none;
      text-align: center;
      color: black;
      font-weight: bold;
      &.active {
        color: $dark1;
        font-weight: bold;
      }
      img {
        width: 25px;
        height: 25px;
        margin: 0 5px 0 5px;
      }
    }
  }
}

.side-drawer.open {
  transform: translateX(0);
}

@media screen and (min-width:768px) {
  .side-drawer {
    display: none;
  }
}
