@import "../../../index.scss";

.topBar {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  // background: $green4;
  background: transparent;
  // border: 1px solid red;
  min-height: 35px;
  .full-title {
    width: 100%;
    text-align: center;
    // height: 3rem;
    a {
      text-decoration: none;
      font-size: 2rem;
      font-weight: 600;
      color: black;
      // color: white;
      // color: red;
      // font-family: Melvis;
    }
  }
  .topBar__link {
    display: none;
    font-size: 0.8rem;
    line-height: 0.8rem;
    text-align: left;
    text-decoration: none;
    color: white;
    text-decoration: none;
    text-shadow: 0px 1px 3px grey;
    i {
      color: white;
      font-size: 1em;
      margin-right: 0.2rem;
    }
  }
}
.topBar.close {
  display: none;
}
@media screen and (min-width:768px) {
  .topBar {
    flex-direction: row;
    height: auto;
    justify-content: flex-end;
    // border: 1px solid red;
    .full-title {
      height: 10rem;
      a {
        color: black;
        line-height: 10rem;
        font-size: 4.5rem;
      }
    }
    .topBar__link {
      display: inline;
      font-size: 1rem;
      line-height: 1.3rem;
      font-weight: 500;
      padding: 0.3rem 0 0.3rem 0.8rem;
      text-shadow: none;
      i {
        margin-right: 0.8rem;
      }
    }
  }
}
