html {
  scroll-behavior: smooth;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  margin: 0 0;
  padding: 0 0;
  font-size: 16px;
  box-sizing: border-box;
  font-family: "Sarala", sans-serif;
  transition: 2s all east-out;
  text-decoration: none;
  font-display: swap;
  // color: black;
}

$green1: #2ca314;
$green2: #02a312;
$green3: #0b5b27;
$green4: #09491f;
$green5: #0c3d1d;

$orange1: #a06519;
$orange2: #965315;
$orange3: #7c4512;
$orange4: #693d0b;

$dark0: #fe662e;
$dark1: #dc391c;
$dark2: #a33c13;
$dark3: #832f0e;
$dark4: #5e250e;
$dark5: #460700;//original
$dark6: #3a0e08;

$silver1: #2c7983;
$silver2: #235d64;
$silver3: #1c4d53;
$silver4: #1d4d55;
$silver5: #153a3f;

$grey1: #d6d6d6;
$grey2: #a0a0a0;
$grey3: #6b6969;
$grey4: #484848;
$grey5: #333333;

@media only screen and (max-width: 768px) {
  * {
    /*CSS animations*/
    -webkit-animation: none !important;
    -moz-animation: none !important;
    -o-animation: none !important;
    -ms-animation: none !important;
    animation: none !important;
  }
}
