@import "../../../index.scss";

.navBar {
  display: none;
  // display: flex;
}

@media screen and (min-width:768px) {
  .navBar {
    flex: 3;
    display: flex;
    justify-content: space-around;
    // border: 1px solid red;
    // width: 100%;
 
    .links {
      width: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      border-top: 1px solid black;
      border-bottom: 1px solid black;
      padding: 0.5rem 0;
      .nav-link {
        padding: 0.15rem 1.2rem;
        font-size: 1.2rem;
        text-decoration: none;
        color: black;
        font-weight: 400;
        letter-spacing: 0.015rem;
        z-index: +100;
        &.active {
          color: white;
          font-weight: bold;
          background: $grey5;
          border-radius: 5px;
        }
        .drop_down_menu {
          width: 300px;
          position: absolute;
          display: flex;
          flex-wrap: wrap;
          top: 4.5rem;
          background: white;
          box-shadow: 1px 2px 2px grey;
          padding: 0.3rem 0.4rem;
          border-bottom-left-radius: 0.3rem;
          border-bottom-right-radius: 0.3rem;
          list-style-type: none;
          li {
            color: black;
            font-size: 1.1rem;
            margin-right: 2rem;
            padding: 0.2rem 0;
            flex: 1 0 34%;
            margin: 5px;
          }
        }
        .drop_down_menu.close {
          display: none;
        }
      }
    }
  }
}
