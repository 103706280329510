@import "../../index.scss";

h2 {
  color: $dark4;
  padding: 0.8rem 0 0.4rem 0;
  font-size: 1.4rem;
  line-height: 2rem;
}

p {
  font-size: 1rem;
  span {
    font-size: 1rem;
    font-weight: 900;
  }
  a {
    font-weight: 900;
    font-size: 1rem;
    color: $dark2;
    text-decoration: none;
  }
  ul {
    padding: 0.2rem 0 0.2rem 1.6em;
    li {
      padding-bottom: 0.12rem;
    }
  }
  b {
    font-size: 1rem;
    color: $dark3;
  }
}

@media screen and (min-width:768px) {
  h2 {
    font-size: 1.6rem;
  }
  p {
    font-size: 18px;
    span {
      font-size: 1.1rem;
    }
    a {
      cursor: pointer;
      font-size: 1.2rem;
      &:hover {
        text-decoration: underline;
      }
    }
    ul {
      li {
        font-size: 18px;
      }
    }
    b {
      font-size: 1.1rem;
    }
  }
}
