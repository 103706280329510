@import "../../index.scss";
.news {
  width: 100%;
  h1 {
    display: inline-block;
    border-bottom: 4px solid $dark1;
    line-height: 1.8rem;
    font-size: 1.4rem;
    padding: 0;
    margin: 0.6rem 0 0.6rem 0.3rem;
  }
  .home__box__1-1 {
    background: $silver4;
    .home__box {
      width: 100%;
    }
    .home__rightBox {
      display: flex;
      flex-direction: column;
      color: white;
      width: 100%;
      padding: 0 0.3rem;
    }
  }
  .home__box__11 {
    padding: 0 0.6rem;
  }
  .home__box__2 {
    padding: 0 0.6rem;
    .home__box__2-layout {
      display: flex;
      flex-direction: column;
    }
  }
  .adv {
    a {
      img {
        width: 100%;
        height: auto;
      }
    }
  }
}
@media screen and (max-width:767px) {
  .news {
    .home__box__1 {
      .home__rightBox {
        h3 {
          display: none;
        }
      }
    }
    .home > div:nth-child(2) {
      margin-top: auto;
    }
  }
}
@media screen and (min-width: 768px) {
  .news {
    margin-top: 1rem;
    padding-bottom: 3rem;
    h1 {
      display: none;
      font-size: 2rem;
      line-height: 2.5rem;
      color: $orange2;
      margin: 0.8rem 0;
    }
    .home__box__1-1 {
      position: relative;
      align-items: start;
      display: flex;
      flex-direction: column;
      .home__box {
        width: 100%;
        height: 100%;
        margin: 0;
      }
    }
    .home__box__11 {
      padding: 0;
      position: relative;
      align-items: start;
      height: 640px;
      display: flex;
      flex-direction: row;
      .home__rightBox {
        width: 100%;
        height: 100%;
        margin-right: 2rem;
        display: grid;
        grid-template-rows: 1fr 1fr 1fr;
        padding: 10px 0;
      }
      .home__adBox {
        display: flex;
        width: 320px;
        height: 100%;
        justify-content: right;
        align-items: center;
      }
    }
    .home__box__2 {
      // border: 1px solid red;
      padding-bottom: 0.8rem;
      margin-bottom: 1rem;
      .home__box__2-layout {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1rem;
        justify-content: space-between;
      }
      .no-add {
        display: none;
      }
    }
  }
}
