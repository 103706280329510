@import "./index.scss";

.app {
  display: flex;
  flex-direction: column;
  position: relative;
  .app-wrapper {
    display: flex;
    .app-body {
      position: relative;
      margin: 0 auto;
    }
  }
}
@media screen and (min-width:768px) {
}
@media screen and (min-width:1024px) {
  .app {
    .app-wrapper {
      .app-body {
        width: 1024px;
      }
    }
  }
}
