@import "../../index.scss";

.bars {
  display: flex;
  flex-direction: column;
  min-width: 300px;
  z-index: +999;
  .bars__links {
    min-height: 45px;
    background: black;
  }
}
@media screen and (min-width:768px) {
  .bars {
    width: 100%;
    background: linear-gradient(
      to right,
      $silver3 35%,
      $silver2 50%,
      $silver3 65%
    );
    background: transparent;
    .full-title {
      height: 10rem;
      a {
        line-height: 10rem;
        font-size: 4.5rem;
      }
    }
    .bars__links {
      margin: 0 auto;
      width: 1200px;
      display: flex;
      flex-direction: column;
      background: transparent;
    }
  }
}
@media screen and (min-width:1024px) {
  .bars {
    width: 100%;
    background: linear-gradient(
      to right,
      $silver3 35%,
      $silver2 50%,
      $silver3 65%
    );
    background: transparent;
    .bars__links {
      margin: 0 auto;
      display: flex;
      flex-direction: column;
    }
  }
  .bars.small {
    height: 3.5rem;
  }
}
