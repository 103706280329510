@import "../../index.scss";

.mainNews {
  display: flex;
  .artBox-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    img {
      height: 300px;
      width: 100%;
      object-fit: cover;
      overflow: hidden;
    }
    .artBox-desc {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 0 0.5rem;
      background: linear-gradient(
        to bottom,
        rgba(143, 143, 143, 0) 0%,
        rgba(24, 24, 24, 0.7) 20%,
        rgba(24, 24, 24, 0.95) 90%
      );
      h2 {
        padding-top: 0.8rem;
        line-height: 1.7rem;
        font-size: 1.4rem;
        color: white;
        font-weight: normal;
      }
      .artBox-desc-other {
        a {
          font-size: 1rem;
          padding: 0.1rem 0;
          font-weight: 600;
          color: $dark1;
          margin-right: 0.2rem;
        }
        span {
          font-size: 0.9rem;
          text-transform: lowercase;
          color: rgb(168, 168, 168);
        }
      }
    }
  }
}
.boxWrapper {
  .artBox-wrapper {
    height: 100%;
    margin: 0;
    padding: 0.5rem 0;
    display: flex;
    flex-direction: row;
    img {
      width: 120px;
      height: 100%;
      object-fit: cover;
      overflow: hidden;
    }
    .artBox-desc {
      padding-left: 0.4rem;
      display: flex;
      flex-direction: column;
      width: 65%; //???????????????
      color: black;
      h2 {
        font-weight: 500;
        line-height: 1.3rem;
        font-size: 1.2rem;
        padding: 0;
      }
      .artBox-desc-other {
        margin-top: auto;
        padding-top: 0.4rem;
        //to check line-hight
        line-height: 0.9rem;
        a {
          font-size: 0.95rem;
          font-weight: 600;
          color: $dark1;
          margin-right: 0.2rem;
        }
        span {
          font-size: 0.8rem;
          color: $grey2;
          font-weight: 600;
          text-transform: lowercase;
        }
      }
    }
  }
}
.boxWrapper:last-child {
  border: none;
}
.boxWrapper.mainBox {
  display: flex;
  box-shadow: 0 0 13px 0 rgba(5, 30, 13, 0.1);
  margin-bottom: 0.8rem;
  border-bottom: none;
  .artBox-wrapper {
    display: flex;
    flex-direction: column;
    padding: 0;
    width: 100%;
    img {
      width: 100%;
      height: 240px;
      object-fit: cover;
      overflow: hidden;
    }
    .artBox-desc {
      display: flex;
      flex-direction: column;
      height: 110px;
      width: 100%;
      padding: 0.2rem 0.3rem;
      h2 {
        line-height: 1.5rem;
        font-size: 1.25rem;
        font-weight: 500;
      }
      .artBox-desc-other {
        margin: 0;
        margin-top: auto;
        a {
          color: $dark1;
          font-size: 1.1rem;
        }
        span {
          font-size: 1rem;
          color: $grey2;
        }
      }
    }
  }
}
.boxWrapper.mainBox.new {
  .artBox-wrapper {
    width: 100%;
  }
}
.boxWrapper.box1 {
  height: 120px;
  border-top: 1px solid $dark3;
  .artBox-wrapper {
    .artBox-desc {
      h2 {
        color: white;
      }
    }
  }
}
.boxWrapper.box2 {
  display: flex;
  height: 120px;
  border-bottom: 1px solid $grey4;
  .artBox-wrapper {
    img {
      width: 120px;
    }
    .artBox-desc {
      h2 {
        color: black;
      }
      span {
        color: rgb(168, 168, 168);
      }
    }
  }
}
.boxWrapper.box2:last-child {
  border-bottom: none;
}
.boxWrapper.box3 {
  height: 120px;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid $silver1;
  .artBox-wrapper {
    .artBox-desc {
      h2 {
        color: white;
      }
      .artBox-desc-other {
        span {
          color: rgb(216, 216, 216);
        }
      }
    }
  }
}
.boxWrapper.box4 {
  display: flex;
  height: 120px;
  border-bottom: 1px solid $grey4;
}
.boxWrapper.box4:last-child {
  border: none;
}
.boxWrapper.sideBox {
  display: flex;
  padding: 0;
  height: 120px;
  box-shadow: none;
  color: black;
  border-bottom: 1px solid $grey4;
  .artBox-wrapper {
    .artBox-desc {
      h2 {
        color: black;
        line-height: 1.1rem;
        font-weight: 500;
      }
    }
  }
  h2 {
    color: black;
  }
}
.boxWrapper.sideBox:last-child {
  border: none;
}
@media screen and (min-width:768px) {
  .mainNews {
    min-width: 50%;
    height: 100%;
    height: 500px;
    margin: 0;
    position: relative;
    .artBox-wrapper {
      width: 100%;
      height: 100%;
      position: relative;
      img {
        height: 100%;
        object-fit: cover;
        overflow: hidden;
      }
      .artBox-desc {
        border: none;
        width: 100%;
        margin: 0;
        position: absolute;
        left: 0;
        bottom: 0;
        background: linear-gradient(
          to bottom,
          rgba(143, 143, 143, 0) 0%,
          rgba(24, 24, 24, 0.5) 20%,
          rgba(24, 24, 24, 0.75) 90%
        );
        h2 {
          text-shadow: none;
          font-weight: 400;
          cursor: pointer;
          padding-top: 0.8rem;
          margin: 0rem 0.3rem;
          line-height: 2.4rem;
          font-size: 2rem;
          color: white;
        }
        .artBox-desc-other {
          padding: 0.3rem;
          line-height: 1.6rem;
          a {
            font-size: 1.4rem;
            color: $dark1;
            margin-right: 0.3rem;
            &:hover {
              text-decoration: underline;
            }
          }
          span {
            font-size: 1.2rem;
          }
        }
      }
    }
  }
  .boxWrapper {
    color: white;
    display: flex;
    flex-direction: column;
    padding: 0.8rem 0;
    border: none;
    border-bottom: 1px solid rgb(180, 180, 180);
    .artBox-wrapper {
      padding: 0;
      img {
        padding: 0;
        width: 150px;
      }
      .artBox-desc {
        position: relative;
        h2 {
          font-size: 1.2rem;
          line-height: 1.4rem;
          text-shadow: none;
          margin: 0;
        }
        .artBox-desc-other {
          line-height: 1.2rem;
          a {
            line-height: 1.2rem;
            font-size: 1rem;
            color: $dark1;
            margin-right: 0.2rem;
            &:hover {
              text-decoration: underline;
            }
          }
          span {
            font-size: 0.8rem;
          }
        }
      }
    }
  }
  .boxWrapper.mainBox {
    padding: 0;
    .artBox-wrapper {
      img {
        height: 200px;
      }
    }
  }
  .boxWrapper.mainBox.bigBox {
    height: 340px;
    margin: 0;
    .artBox-wrapper {
      height: 100%;
      img {
        width: 100%;
        height: 300px;
        object-fit: cover;
        overflow: hidden;
      }
      .artBox-desc {
        .artBox-desc-other {
          a {
            font-size: 1.1rem;
          }
          span {
            font-size: 1rem;
          }
        }
      }
    }
  }
  .boxWrapper.sideBox {
    padding: 0.5rem 0;
    height: 120px;
    box-shadow: none;
    .artBox-wrapper {
      img {
        height: 100%;
        object-fit: cover;
        overflow: hidden;
      }
      .artBox-desc {
        h2 {
          color: black;
          font-size: 1rem;
          line-height: 1.1rem;
          font-weight: 500;
        }
      }
    }
    h2 {
      color: black;
    }
  }
  .boxWrapper.box1 {
    //fix box hight
    height: 139px;
    border: none;
    border-bottom: 1px solid $grey4;
    .artBox-wrapper {
      .artBox-desc {
        h2 {
          font-size: 1.15rem;
        }
      }
    }
  }
  .boxWrapper.box1:last-child {
    border: none;
  }
  .boxWrapper.box2 {
    height: 210px;
    width: 100%;
    .artBox-wrapper {
      width: 100%;
      img {
        padding: 0;
        width: 320px;
        height: auto; //????
        object-fit: cover;
        overflow: hidden;
      }
      .artBox-desc {
        width: 100%;
        h2 {
          font-size: 1.4rem;
          line-height: 1.8rem;
        }
        .artBox-desc-other {
          margin-bottom: auto;
          position: absolute;
          bottom: 0;
          // color: $green1;
          color: $dark1;
          letter-spacing: 0.05rem;
          font-weight: bold;
          font-size: 1.2rem;
          a {
            font-size: 1.2rem;
          }
          span {
            font-size: 1rem;
          }
        }
      }
    }
  }
  //check is it active yet
  .boxWrapper.box2.news2 {
    height: 180px;
    .artBox-wrapper {
      img {
        width: 320px;
        height: 160px;
      }
      .artBox-desc {
        h2 {
          color: white;
        }
      }
    }
  }
  .boxWrapper.box2.news3 {
    min-height: 200px;
    .artBox-wrapper {
      min-height: 160px;
      img {
        min-width: 240px;
      }
    }
  }
  .boxWrapper.box3 {
    background: $grey5;
    height: 300px;
    padding: 0;
    padding-bottom: 0.2rem;
    border: none;
    border-radius: 10px;
    .artBox-wrapper {
      display: flex;
      flex-direction: column;
      height: 100%;
      img {
        border-radius: 0;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        width: 100%;
      }
      .artBox-desc {
        padding: 0.3rem;
        width: 100%;
        height: 150px;
        h2 {
          font-size: 1.2rem;
        }
        .artBox-desc-other {
          color: $dark1;
          span {
            color: $grey1;
          }
        }
      }
    }
  }
  .boxWrapper.box4 {
    box-shadow: 0 0 13px 0 rgba(5, 30, 13, 0.1);
    border: none;
    height: 140px;
    padding: 0;
    .artBox-wrapper {
      img {
        width: 200px;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        // border-top-left-radius: 10px;
        // border-bottom-left-radius: 10px;
      }
      .artBox-desc {
        padding: 0.2rem 0.4rem;
        a {
          font-size: 1.1rem;
        }
        span {
          font-size: 0.9rem;
        }
      }
    }
  }
}
